var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col px-1"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{class:_vm.match.groupId ? 'w-1/3' : 'w-1/2'},[_vm._l((_vm.predictions['home']),function(user){return _c('BaseLink',{key:user.userId,attrs:{"to":{ name: 'predictions', query: { userId: user.userId } }}},[_c('span',{class:[
            'inline-block mx-1 my-0.5 px-2 py-1.5 leading-3 rounded-full border truncate max-w-[100px] sm:max-w-[200px] overflow-hidden whitespace-nowrap	',
            _vm.currentUserId === user.userId
              ? _vm.currentUserPillColor('home')
              : _vm.pillColor('home'),
          ]},[_vm._v(" "+_vm._s(user.name)+" ")])])}),(!_vm.hasPredictions('home'))?_c('div',{staticClass:"px-2"},[_vm._v(" No Predictions ")]):_vm._e()],2),(_vm.match.groupId)?_c('div',{staticClass:"w-1/3"},[_vm._l((_vm.predictions['draw']),function(user){return _c('span',{key:user.userId,class:[
          'inline-block mx-1 my-0.5 px-2 py-1.5 leading-3 rounded-full border truncate max-w-[100px] sm:max-w-[200px] overflow-hidden whitespace-nowrap	',
          _vm.currentUserId === user.userId
            ? _vm.currentUserPillColor('draw')
            : _vm.pillColor('draw'),
          ,
        ]},[_vm._v(" "+_vm._s(user.name)+" ")])}),(!_vm.hasPredictions('draw'))?_c('div',{staticClass:"px-2"},[_vm._v(" No Predictions ")]):_vm._e()],2):_vm._e(),_c('div',{class:_vm.match.groupId ? 'w-1/3' : 'w-1/2'},[_vm._l((_vm.predictions['away']),function(user){return _c('span',{key:user.userId,class:[
          'inline-block mx-1 my-0.5 px-2 py-1.5 leading-3 rounded-full border truncate max-w-[100px] sm:max-w-[200px] overflow-hidden whitespace-nowrap	',
          _vm.currentUserId === user.userId
            ? _vm.currentUserPillColor('away')
            : _vm.pillColor('away'),
        ]},[_vm._v(" "+_vm._s(user.name)+" ")])}),(!_vm.hasPredictions('away'))?_c('div',{staticClass:"px-2"},[_vm._v(" No Predictions ")]):_vm._e()],2)]),_c('div',{staticClass:"my-4"},[_c('MatchPredictionsBar',{attrs:{"predictions":_vm.predictions,"result":_vm.result,"match":_vm.match}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }